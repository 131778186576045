import { Bubble } from "./fns/bubble";
import { createElement } from "./fns/createElement";
import { createIframe } from "./fns/createIframe";
import {
  getInititalMessage,
  postMessageToChild,
  getReinitMessage,
} from "./fns/iframeMessage";
import { ISdkChatOptions } from "./libs";
(function () {
  window.ams_sdk = {
    chat: {
      load: async (options) => {
        const iframe = await setupIframe(options);
        return {
          reload: (token: string, newOptions: ISdkChatOptions) => {
            reloadContent(token, { ...options, ...newOptions });
          },
          setAccessToken:
            !options.secure || options.secure.enabled === false
              ? null
              : (accessToken: string) => {
                  if (!iframe) return console.error("iframe not found");
                  if (!options.secure || options.secure.enabled === false)
                    return console.error("secure mode is not enabled");
                  postMessageToChild(iframe, {
                    sender: "ams_sdk",
                    topic: "update-access-token",
                    data: { accessToken },
                  });
                },
        };
      },
    },
  };
  async function setupIframe(options: ISdkChatOptions) {
    if (options.mode === "bubble") {
      const iframe = await setupBubble(options);
      if (!iframe) return;
      return iframe as HTMLIFrameElement;
    }
    // full_screen
    else {
      const iframe = await setupFullPage(options);
      if (!iframe) return;
      return iframe;
    }
  }

  async function setupBubble(options: ISdkChatOptions) {
    return new Promise((resolve, reject) => {
      if (!options.channelId) reject("channelId is required");
      let isIframeinit = false;
      const bubble = new Bubble({
        ...options.bubble,
        onToggle: async () => {
          if (isIframeinit) return;
          // create iframe
          if (bubble.frameObj && bubble.frameObj.body) {
            const iframe = await createIframe(bubble.frameObj.body);
            postMessageToChild(iframe, getInititalMessage(options));
            isIframeinit = true;
            resolve(iframe);
            return;
          }
          reject("iframe not found");
        },
      });
    });
  }

  const setupFullPage = async (options: ISdkChatOptions) => {
    try {
      const frameContainer = createElement("div", "ams_sdk-frame-container");
      document.body.appendChild(frameContainer);
      // set the container to full page
      frameContainer.style.position = "fixed";
      frameContainer.style.top = "0";
      frameContainer.style.left = "0";
      frameContainer.style.width = "100%";
      frameContainer.style.height = "100%";
      const iframe = await createIframe(frameContainer);

      postMessageToChild(iframe, getInititalMessage(options));
      if (!iframe) return;
      return iframe;
    } catch (error) {
      console.error("init iframe error ", error);
      throw error;
    }
  };

  const reloadContent = (token: string, options: ISdkChatOptions) => {
    const iframe = document.getElementById(
      "ams_sdk_frame"
    ) as HTMLIFrameElement;
    if (!iframe) return console.error("iframe not found");
    postMessageToChild(iframe, getReinitMessage(token, options));
  };
})();
